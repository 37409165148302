<template>
  <header>
    <b-navbar toggleable="md" type="dark" fixed="top" variant="public">
      <b-container>
        <b-navbar-brand href="#"
          ><img src="@/images/BCnavbar.png"
        /></b-navbar-brand>
        <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

        <b-collapse is-nav id="nav_collapse">
          <b-navbar-nav toggleable="false">
            <b-nav-item :to="{ name: 'home' }" exact
              ><fa-icon :icon="['far', 'home']" size="lg" /> Home</b-nav-item
            >
            <b-nav-item :to="{ name: 'eventList' }"
              ><fa-icon :icon="['far', 'calendar-day']" size="lg" />
              Eventi</b-nav-item
            >
            <b-nav-item :to="{ name: 'subscribe' }"
              ><fa-icon :icon="['far', 'pencil']" size="lg" />
              Iscrizioni</b-nav-item
            >
            <b-nav-item :to="{ name: 'instructions' }"
              ><fa-icon :icon="['far', 'chalkboard-teacher']" size="lg" />
              Istruzioni</b-nav-item
            >
            <b-nav-item :to="{ name: 'bot' }"
              ><fa-icon :icon="['far', 'robot']" size="lg" /> Bot</b-nav-item
            >
            <b-nav-item :to="{ name: 'help' }"
              ><fa-icon :icon="['far', 'question-square']" size="lg" />
              Aiuto</b-nav-item
            >
            <b-nav-item :to="{ name: 'info' }"
              ><fa-icon :icon="['far', 'info-square']" size="lg" />
              Info</b-nav-item
            >
          </b-navbar-nav>

          <b-navbar-nav class="ml-auto">
            <b-nav-item-dropdown :text="currentRegion">
              <b-dropdown-item-button @click="clearRegion"
                >Cambia Regione</b-dropdown-item-button
              >
            </b-nav-item-dropdown>

            <b-nav-item href="#" @click="login" v-if="!user.isAuthenticated"
              >Accedi</b-nav-item
            >
            <b-nav-item-dropdown v-else :text="user.name">
              <b-dropdown-item :to="privateAreaUrl"
                >Area Utente</b-dropdown-item
              >
              <b-dropdown-item href="#">Profilo Utente</b-dropdown-item>
              <b-dropdown-item href="#">Cambio Password</b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item-button @click="logout()"
                >Esci</b-dropdown-item-button
              >
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
  </header>
</template>

<script>
import userAuth from "../../mixins/userAuth.js";
import authenticationService from "../../services/AuthenticationService.js";
import { regions } from "../../shared/regions.js";

export default {
  name: "NavbarPublic",
  mixins: [userAuth],
  computed: {
    privateAreaUrl() {
      return isNaN(this.user.name)
        ? { name: "adminHome" }
        : { name: "userHome" };
    },
    username() {
      return this.$store.state.user.name;
    },
    currentRegion() {
      return this.$store.state.WReg == ""
        ? "(...)"
        : regions.find((reg) => reg.id == this.$store.state.WReg).name;
    },
  },
  methods: {
    login() {
      authenticationService.login();
    },
    logout() {
      authenticationService.logout();
    },
    clearRegion() {
      this.$store.dispatch("clearWorkingRegion");
    },
  },
};
</script>
