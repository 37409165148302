<template>
  <b-form-select
    :value="value"
    @change.native="$emit('input', $event.target.value)"
    :size="sizeProp"
    :class="classProp"
  >
    <option value v-if="emptyOption == 'forcePick'" disabled>
      (seleziona...)
    </option>
    <option value v-if="emptyOption == 'allRegions'">Tutte le regioni</option>
    <option value="A">Nazionale</option>
    <option value="B">Abruzzo</option>
    <option value="C">Basilicata</option>
    <option value="D">Calabria</option>
    <option value="E">Campania</option>
    <option value="F">Emilia Romagna</option>
    <option value="G">Friuli Venezia Giulia</option>
    <option value="H">Lazio</option>
    <option value="I">Liguria</option>
    <option value="L">Lombardia</option>
    <option value="M">Marche</option>
    <option value="N">Molise</option>
    <option value="O">Piemonte</option>
    <option value="P">Puglia</option>
    <option value="Q">Sardegna</option>
    <option value="R">Sicilia</option>
    <option value="S">Toscana</option>
    <option value="T">Trentino Alto Adige</option>
    <option value="U">Umbria</option>
    <option value="V">Valle d&#39;Aosta</option>
    <option value="Z">Veneto</option>
    <option value="W" v-if="showTest">TEST</option>
  </b-form-select>
</template>

<script>
export default {
  name: "RegionPicker",
  props: {
    emptyOption: {
      type: String,
      default: "forcePick",
      validator(value) {
        return value == "forcePick" || value == "allRegions";
      },
    },
    showTest: {
      type: [Boolean, String],
      default: false,
    },
    sizeProp: {
      type: String,
      default: "",
    },
    classProp: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: null,
    },
  },
};
</script>
