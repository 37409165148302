<template>
  <div>
    <h1>This is an event detail page</h1>
  </div>
</template>

<script>
export default {
  name: "EventDetail",
  props: {
    id: {
      type: [Number, String],
      required: true,
      validator(value) {
        return Number.isInteger(Number(value));
      },
    },
  },
};
</script>
