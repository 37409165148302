<template>
  <div>
    <b-jumbotron>
      <template #header>
        <img src="../../images/BCfull.png" />
      </template>
      <template #lead
        >BuonaCaccia &egrave; la piattaforma online per le iscrizioni agli
        eventi scout dell' <a href="www.agesci.it" target="_blank">AGESCI</a>.
      </template>
    </b-jumbotron>

    <b-row>
      <b-col>
        <table>
          <tr>
            <td style="vertical-align: top">
              <p style="margin-top: 0px">
                <em
                  >Gli eventi disponibili, nelle regioni evidenziate a lato,
                  sono indicati nella
                  <router-link :to="{ name: 'eventList' }"
                    >pagina eventi</router-link
                  >
                  con tutte le informazioni relative.
                </em>
                <br />
              </p>
              <img alt="PayPal" src="@/images/paypal_banner.gif" />
              <p>
                <strong>I censiti delle regioni abilitate</strong>, possono
                pagare le quote d'iscrizione con
                <a href="http://www.paypal.it" target="_blank">
                  <strong>PayPal</strong></a
                >.
              </p>
            </td>
          </tr>
        </table>

        <br />
        <p style="font-size: 120%">
          <span class="tiny">01/01/2019</span>&nbsp;
          <img alt="BC" src="@/images/BCtiny.png" />&nbsp;
          <strong>BC Next Generation!</strong>
          <br />
          <em>Questo è un sistema di test...</em>
        </p>
        <p>
          <span class="tiny">01/02/2017</span>&nbsp;
          <img alt="BC" src="@/images/BCtiny.png" />&nbsp;
          <strong>Sei anni di BuonaCaccia in 8 minuti!</strong>
          <br />
          <em
            >Un
            <a href="http://blog.echopod.net/2016/12/6-anni-di-bc/">video</a>
            alimentato direttamente dai cambiamenti al codice sorgente.
          </em>
          <br />Ok, sar&agrave; molto "nerd" ma... ha tutto un suo fascino, non
          trovate?
        </p>
        <p>&nbsp;</p>
        <p>
          <b-button variant="primary" :to="{ name: 'info' }"
            >Ulteriori informazioni &raquo;</b-button
          >
        </p>
      </b-col>

      <b-col cols="4">
        <bc-newsfeed tweets="10" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import bcNewsfeed from "../../shared/NewsFeed.vue";

export default {
  name: "Home",
  components: {
    "bc-newsfeed": bcNewsfeed,
  },
};
</script>
