<template>
  <div>
    <h2>Diagnostica</h2>
    <p class="lead">
      <em>BuonaCaccia NextGen</em>
    </p>
    <p>
      L'applicazione per le iscrizioni è: [OnlineStatus]<br />
      L'archivio della Segreteria Centrale è: [AgesciStatus]<br />
      L'archivio in uso è: [ArchiveInUse]<br />
      L'ambiente in uso è: {{ NodeEnv }}
    </p>
    <p>
      Regione di provenienza: [Regione] (<b-link href="#" @click="clearRegion"
        >cambia</b-link
      >)
    </p>
    <hr />
    <h2>Credits</h2>
    <p>
      <em>BuonaCaccia</em> è un'applicazione ideata e sviluppata da Edoardo
      Pinci.&nbsp;
      <a href="http://twitter.com/EPinci">
        <fa-icon :icon="['fab', 'twitter']" size="lg" />
      </a>
      <a href="http://blog.echopod.net/">
        <fa-icon :icon="['far', 'link']" size="lg" />
      </a>
    </p>
    <p style="margin-bottom: 5px">Un caloroso ringraziamento:</p>
    <ul style="margin-top: 0px">
      <li>a <em>Maria Grazia Molle</em> per il concept logo.</li>
      <li>
        a <em>Francesco Gabbuti</em> per il tirocinio e la tesi di laurea
        sull'interfaccia utente di BC.
      </li>
      <li>
        a <em>Cristina, Paola e Loredana</em>, della Segreteria Regionale Lazio,
        pioniere delle iscrizioni elettroniche, per il supporto nella
        definizione della componente amministrativa.
      </li>
      <li>
        a <em>Dale, Monia, Antonio e Catello</em>, delle Segreterie Regionali
        Emilia Romagna, Sicilia e Campania, per aver sperimentato, a turno,
        nuove funzionalità.
      </li>
      <li>a <em>Francesco Santini</em> per l'incredibile supporto.</li>
    </ul>
    <p style="font-size: smaller">
      Le icone utilizzate sono opera di
      <a href="http://www.fontawesome.com/">FontAwesome</a>.
    </p>
    <hr />
    <h2>Accessi giornalieri</h2>
    <br />
  </div>
</template>

<script>
export default {
  name: "About",
  computed: {
    NodeEnv() {
      return process.env.NODE_ENV;
    },
  },
  created() {
    let widget = document.createElement("script");
    widget.setAttribute(
      "src",
      "//widgets.clicky.com/flashy/?site_id=66357644&sitekey=77a1b477dc55dcb561fbd2ae230726d2&w=900&h=300&date=last-60-days&type=actions&title=&hide_y=0&hide_title=1&hide_branding=1"
    );
    widget.setAttribute("type", "text/javascript");
    document.body.appendChild(widget);
  },
  methods: {
    clearRegion() {
      this.$store.dispatch("clearWorkingRegion");
    },
  },
};
</script>
