<template>
  <div class="row">
    <div class="col-md-4">
      <h4>Problemi con la <strong>registrazione</strong>?</h4>
      <p>
        <a href="" @click.prevent="updateMessage('MissingPid')"
          >Non ho il mio codice personale, dove posso trovarlo?</a
        >
      </p>
      <p>
        <a href="" @click.prevent="updateMessage('UnrecognizedPID')"
          >BuonaCaccia mi dice che il mio codice personale non esiste o non
          &eacute; corretto, perch&eacute;?</a
        >
      </p>
      <p>
        <a href="" @click.prevent="updateMessage('WrongEmailAddress')"
          >L'indirizzo email che BuonaCaccia mi propone &eacute; sbagliato, come
          posso correggerlo?</a
        >
      </p>
      <p>
        <a href="" @click.prevent="updateMessage('MissingEmail')"
          >Non ricevo email da BuonaCaccia o l'ho persa, come posso ottenerne
          un'altra?</a
        >
      </p>
      <h4>Problemi con i <strong>pagamenti</strong>?</h4>
      <p>
        <a href="" @click.prevent="updateMessage('HowToPay')"
          >Come e dove si effettua il pagamento?</a
        >
      </p>
      <p>
        <a href="" @click.prevent="updateMessage('HowToPayPal')"
          >Come si fa il pagamento elettronico nella mia regione?</a
        >
      </p>
      <p>
        <a href="" @click.prevent="updateMessage('HowToSendReceipt')"
          >Come posso inviare la ricevuta del pagamento?</a
        >
      </p>
      <p>
        <a href="" @click.prevent="updateMessage('Refunds')"
          >Come si fa ad avere un rimborso?</a
        >
      </p>
      <h4>Problemi con la <strong>scheda elettronica</strong>?</h4>
      <p>
        <a href="" @click.prevent="updateMessage('WrongEForm')"
          >Ho compilato la scheda sbagliata, come la correggo?</a
        >
      </p>
      <p>
        <a href="" @click.prevent="updateMessage('CannotCompleteEForm')"
          >Ho problemi a compilare la scheda...</a
        >
      </p>
      <h4>Problemi con la <strong>liberatoria</strong>?</h4>
      <p>
        <a href="" @click.prevent="updateMessage('SendingRelease')"
          >Come posso inviare la liberatoria?</a
        >
      </p>
      <h4>Sei un <strong>capo campo</strong>?</h4>
      <p>
        <a href="" @click.prevent="updateMessage('DataAccess')"
          >Come posso accedere ai dati del mio campo?</a
        >
      </p>
      <h4>Altri problemi?</h4>
      <p>
        <a href="" @click.prevent="updateMessage('MissingEvent')"
          >Se il campo che cerco non &eacute; in elenco?</a
        >
      </p>
      <p>
        <a href="" @click.prevent="updateMessage('AdminIssue')"
          >Come posso contattare la mia segreteria regionale?</a
        >
      </p>
      <p>
        <a href="" @click.prevent="updateMessage('TechnicalIssue')"
          >In caso di problemi tecnici con il sito?</a
        >
      </p>
    </div>
    <div class="col-md-8" v-if="mainMessage != null">
      <b-card bg-variant="danger" text-variant="white">
        <p class="card-text" v-if="mainMessage == 'MissingPid'">
          Il tuo capounit&agrave; o il tuo capogruppo hanno il tuo codice
          personale.<br />
          In caso di emergenza puoi chiederlo alla tua segreteria regionale.
        </p>
        <p class="card-text" v-else-if="mainMessage == 'UnrecognizedPID'">
          BC verifica in tempo reale il tuo codice personale nell'archivio dei
          censimenti della <strong>segreteria centrale</strong>. Se BC ti
          risponde che il tuo codice non esite ed hai verificato di star usando
          quello corretto, questo pu&ograve; significare che ci sono dei
          <strong>problemi con il tuo censimento</strong> che devi verificare
          con la tua segreteria regionale.
        </p>
        <p class="card-text" v-else-if="mainMessage == 'WrongEmailAddress'">
          BC consente ai capi di utilizzare
          <strong
            >esclusivamente l'indirizzo email specificato nei censimenti</strong
          >. Una volta corretto l'indirizzo nel censimento, BC utilizzer&agrave;
          automaticamente il nuovo indirizzo. <br />
          Per correggere l'indirizzo nel censimento, chiedi al tuo capogruppo o
          alla tua segreteria regionale.
        </p>
        <p class="card-text" v-else-if="mainMessage == 'MissingEmail'">
          Se registri nuovamente l'iscrizione che ti interessa, BC si
          accorger&agrave; dell'esistenza della precedente e, senza perderdere i
          dati acquisiti, ti reinvier&agrave; la mail pi&ugrave; opportuna.
        </p>
        <p class="card-text" v-else-if="mainMessage == 'HowToPay'">
          <strong>Ogni regione ha metodi di pagamento differenti</strong>, nella
          pagina di stato della tua iscrizione trovi un link al tuo sito
          regionale con le indicazioni del caso.<br />
          <strong
            >Se la tua regione &eacute; abilitata al pagamento
            elettronico</strong
          >, sempre nella pagina di stato trovi il link da usare.
        </p>
        <p class="card-text" v-else-if="mainMessage == 'HowToPayPal'">
          <strong
            >Solo se la tua regione &eacute; abilitata al pagamento
            elettronico</strong
          >, nella pagina di stato della tua iscrizione trovi il link da usare
          per pagare con PayPal.<br />
          Con il pagamento elettronico non &eacute; necessario inviare la
          ricevuta alla tua segreteria regionale.
        </p>
        <p class="card-text" v-else-if="mainMessage == 'HowToSendReceipt'">
          Il metodo migliore per inviare la ricevuta &eacute;
          <strong
            >caricarla direttamente su BuonaCaccia come indicato nella pagina di
            stato dell'iscrizione</strong
          >.<br />
          E', inoltre, possibile
          <strong
            >inviarla al numero di fax unico 06.99335020 (ricorda di specificare
            il BC dell'iscrizione)</strong
          >.<br />
          In ultima istanza, puoi inviarla alla tua segreteria regionale.<br />
          <em
            >Qualunque forma d'invio, tranne quello elettronico, verr&agrave;
            lavorato dalla tua segreteria regionale solo in orario
            d'apertura</em
          >.
        </p>
        <p class="card-text" v-else-if="mainMessage == 'Refunds'">
          In caso di ritiro di un'iscrizione nei termini previsti, il rimborso
          dipende dal tipo di pagamento fatto.<br />
          Per i pagamenti elettronici con PayPal, il rimborso &eacute;
          automatico.<br />
          Per le altre forme di pagamento o per problemi con il rimborso
          automatico, contatta la tua segreteria regionale.
        </p>
        <p class="card-text" v-else-if="mainMessage == 'WrongEForm'">
          Come indicato nella scheda stessa,
          <strong
            >non &eacute; possibile corregere la scheda elettronica dopo
            l'invio</strong
          >.<br />
          Puoi ritirare (annullare) la tua iscrizione e registrarne una nuova.
        </p>
        <p class="card-text" v-else-if="mainMessage == 'CannotCompleteEForm'">
          Se hai difficolt&agrave; tecnica nel compilare la scheda elettronica o
          nel caricare la tua fotografia, per prima cosa,
          <strong
            >controlla di aver correttamente verificato, senza errori, le parti
            del modulo</strong
          >,<br />
          poi
          <strong
            >prova con un browser differente o un computer differente</strong
          >.<br />
          Se continui ad avere problemi, segnalalo qui sotto descrivendo il
          problema con la massima precisione che ti &eacute; possibile.
        </p>
        <p class="card-text" v-else-if="mainMessage == 'SendingRelease'">
          Il metodo migliore per inviare la liberatoria &eacute;
          <strong
            >caricarla direttamente su BuonaCaccia come indicato nella pagina di
            stato dell'iscrizione</strong
          >.<br />
          E', inoltre, possibile
          <strong>inviarla al numero di fax unico 06.99335020</strong>.<br />
          In ultima istanza, puoi inviarla alla tua segreteria regionale.<br />
          <em
            >Qualunque forma d'invio, tranne quello elettronico, verr&agrave;
            lavorato dalla tua segreteria regionale solo in orario
            d'apertura</em
          >.
        </p>
        <p class="card-text" v-else-if="mainMessage == 'DataAccess'">
          L'accesso ai dati del capmpo pu&ograve; avvenire solo tramite
          autorizzazione della segreteria regionale.<br />
          <em
            >Contatta direttamente la tua segreteria per le procedure
            necessarie</em
          >.
        </p>
        <p class="card-text" v-else-if="mainMessage == 'MissingEvent'">
          BuonaCaccia conosce solo gli eventi che vedi pubblicati!<br />
          Per qualunque altra informazione contatta direttamente la tua
          segreteria regionale.
        </p>
        <p class="card-text" v-else-if="mainMessage == 'AdminIssue'">
          Hai notato che BuonaCaccia ti fornisce un link apposito al tuo sito
          regionale nella pagina di stato della tua iscrizione?
        </p>
        <p class="card-text" v-else-if="mainMessage == 'TechnicalIssue'">
          Puoi segnalare un problema tecnico utilizzando il modulo qui sotto.<br />
          E' importante descrivere il problema con la massima precisione
          possibile per velocizzarne la risoluzione.
        </p>
        <p class="card-text" v-else>
          Hey! Questo caso non &eacute; stato ancora previsto!<br />
          Porta pazienza e riprova pi&ugrave; avanti...
        </p>
      </b-card>
      <br />
      <b-card title="Chi può aiutarmi ulteriormente?">
        <p>
          Puoi usare questo modulo per inviare una segnalazione a
          <span class="font-weight-bold">[indirizzo mail]</span>
        </p>
        <b-form @submit="onSubmit" @reset="onReset" v-if="formShow">
          <div class="form-horizontal">
            <b-form-group label="Il tuo nome e cognome:" :state="nameState">
              <b-form-input
                v-model.trim="form.name"
                type="text"
                placeholder="Nome e cognome"
                :state="nameState"
                required
              />
            </b-form-group>
            <b-form-group label="Il tuo codice censimento:" :state="pidState">
              <b-form-input
                v-model.trim="form.pid"
                type="number"
                placeholder="Codice censimento"
                :state="pidState"
                required
              />
            </b-form-group>
            <b-form-group label="Il tuo indirizzo email:">
              <b-form-input
                v-model.trim="form.email"
                type="email"
                placeholder="Indirizzo email"
                required
              />
            </b-form-group>
            <b-form-group
              label="Ripeti l'indirizzo email:"
              :state="repeatEmailState"
            >
              <b-form-input
                v-model.trim="form.email2"
                type="email"
                placeholder="Indirizzo email"
                :state="repeatEmailState"
                required
              />
            </b-form-group>
          </div>
          <p class="form-inline">
            Se la segnalazione &eacute; relativa ad un iscrizione, specificane
            il protocollo BC&nbsp;
            <b-form-input
              v-model.trim="form.subscription"
              width="87px"
              type="number"
              size="sm"
              placeholder="Numero"
            />
            <br />
            <em
              >(Il protocollo dell&#39;iscrizione si trova in alto, nella
              colonna di destra di ogni email mandata da BuonaCaccia)</em
            >
          </p>
          <b-form-group label="Il soggetto della tua segnalazione:">
            <b-form-input
              v-model.trim="form.subject"
              type="text"
              placeholder="Soggetto"
              required
            />
          </b-form-group>
          <b-form-group label="Descrivi il tuo problema:">
            <b-form-textarea v-model="form.body" rows="3" />
            Se la richiesta &eacute; riferita ad una iscrizione esistente,
            ricorda di inserire il protocollo dell&#39;iscrizione in alto!
          </b-form-group>
          <p>
            <b-button type="submit" variant="primary">Invia!</b-button>&nbsp;
            <b-button type="reset">Annulla</b-button>
          </p>
        </b-form>
      </b-card>
    </div>
  </div>
</template>

<script>
import Toaster from "../../mixins/toaster.js";

export default {
  name: "Help",
  mixins: [Toaster],
  data() {
    return {
      mainMessage: null,
      formShow: true,
      form: {
        name: "",
        pid: "",
        email: "",
        email2: "",
        subscription: "",
        subject: "",
        body: "",
      },
    };
  },
  computed: {
    nameState() {
      if (this.form.name == "") return null;
      return this.form.name.includes(" ") ? "valid" : "invalid";
    },
    pidState() {
      if (this.form.pid == "") return null;
      return this.form.pid > 0 ? "valid" : "invalid";
    },
    repeatEmailState() {
      if (this.form.pid == "") return null;
      return this.form.email == this.form.email2 ? "valid" : "invalid";
    },
  },
  methods: {
    updateMessage(value) {
      this.mainMessage = value;
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.toast("info", "Aiuto", JSON.stringify(this.form));
    },
    onReset(evt) {
      evt.preventDefault();
      /* Reset our form values */
      this.form.name = "";
      this.form.pid = "";
      this.form.email = "";
      this.form.email2 = "";
      this.form.subscription = "";
      this.form.subject = "";
      this.form.body = "";
      /* Trick to reset/clear native browser form validation state */
      this.formShow = false;
      this.$nextTick(() => {
        this.formShow = true;
      });
    },
  },
  created() {
    this.mainMessage = this.$route.query.i;
  },
};
</script>
