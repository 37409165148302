<template>
  <div>
    <h3>BuonaCacciaBot</h3>
    <p>
      BuonaCacciaBot è disponibile su questa pagina, tramite
      <a href="https://join.skype.com/bot/3bd8be25-887f-4501-b3ee-03e61fe1a5f6">
        <fa-icon :icon="['fab', 'skype']" size="lg" /> Skype </a
      >,
      <a href="https://www.messenger.com/t/BuonaCaccia">
        <fa-icon :icon="['fab', 'facebook']" size="lg" /> Facebook
      </a>
      e
      <a href="https://telegram.me/BuonaCacciaBot"
        ><fa-icon :icon="['fab', 'telegram']" size="lg" /> Telegram</a
      >.
      <em class="float-right"
        >Questo bot e le sue funzionalità sono *sperimentali*!
        <a
          href="http://blog.echopod.net/2017/09/che-ne-dite-di-un-buonacacciabot/"
          >Suggerimenti?</a
        >
      </em>
    </p>

    <iframe :src="botFrameSrc" width="100%" height="600px"></iframe>
  </div>
</template>

<script>
import Toaster from "../../mixins/toaster.js";

export default {
  name: "Bot",
  mixins: [Toaster],
  data() {
    return {
      botFrameSrc: null,
    };
  },
  created() {
    $token = this.$http
      .get("/v0.1/uiassist/bottoken")
      .then((response) => {
        this.botFrameSrc =
          "https://webchat.botframework.com/embed/BuonaCacciaBot?t=" +
          response.data.token;
      })
      .catch(() => {
        this.toast(
          "danger",
          "Bot",
          "Ooops! Qualcosa è andato storto nel caricamento del bot..."
        );
      });
  },
};
</script>
