<template>
  <div>
    <h2>Istruzioni importanti per le iscrizioni</h2>
    <p
      v-if="false"
      class="alert alert-danger"
      id="pIsolaOffline"
      runat="server"
    >
      <strong
        >ATTENZIONE: Fino al ripristino del sistema dei censimenti della
        segreteria nazionale non &eacute; possibile fare nessuna correzione ai
        censimenti.</strong
      ><br />
      E' necessario prestare particolare attenzione nel far registrare le
      iscrizioni da capi con il censimento in ordine e l'indirizzo mail
      esatto.<br />
      Questa cosa non &eacute; sotto il controllo di BuonaCaccia. Non &eacute;
      disponibile una previsione del ripristino del servizio.
    </p>
    <p class="alert alert-info">
      L&#39;apertura delle iscrizioni di un evento avviene alle
      <b-badge>9:00</b-badge> della mattina del giorno indicato come apertura
      delle iscrizioni.
    </p>
    <p style="margin-bottom: 0; margin-top: 0">
      Per alcune iscrizioni, salvo eccezioni particolari, è possibile che sia
      previsto che il socio partecipante sia presentato da un capo:<br />
    </p>
    <ul style="margin-top: 0">
      <li>
        Per gli eventi per L/C ed E/G, normalmente il partecipante deve essere
        presentato da un capounit&agrave; della propria unit&agrave;.
      </li>
      <li>
        Per gli eventi per R/S e capi, normalmente il partecipante può
        presentarsi autonomamente con eventuale autorizzazione successiva.
      </li>
    </ul>

    <p style="font-size: medium">
      <strong
        >Dopo aver registrato un&#39;iscrizione ad un evento su BuonaCaccia, per
        completarla, è necessario:</strong
      >
    </p>
    <ul class="fa-ul" style="margin-top: 0">
      <li class="spaced-list-item">
        <span class="fa-li"
          ><fa-icon :icon="['far', 'envelope']" size="lg" fixed-size
        /></span>
        Validare l&#39;iscrizione utilizzando il link inviato per email al capo
        presentatore.
      </li>
      <li class="spaced-list-item">
        <span class="fa-li"
          ><fa-icon :icon="['far', 'coins']" size="lg" fixed-size
        /></span>
        Se previsto, pagare la quota d&#39;iscrizione alla propria Segreteria
        Regionale secondo le modalità indicate sul sito regionale ed inviare a
        BuonaCaccia, tramite upload o fax, la ricevuta del pagamento completa di
        causale debitamente compilata.<br />
        <em
          >Per le regioni che lo hanno attivato, &eacute; possibile pagare
          elettronicamente, con Carta di Credito, tramite PayPal direttamente
          dalla pagina di stato dell&#39;iscrizione.</em
        >
      </li>
      <li class="spaced-list-item">
        <span class="fa-li"
          ><fa-icon :icon="['far', 'file-signature']" size="lg" fixed-size
        /></span>
        Se previsto dal tipo di evento: far pervenire in Segreteria Regionale,
        correttamente compilata, la scheda cartacea (solo in originale) o la
        liberatoria inviata da BuonaCaccia (valida in originale, caricata
        tramite BuonaCaccia o inviata al
        <strong>numero unico di fax 06.99335020</strong>).
      </li>
      <li class="spaced-list-item">
        <span class="fa-li"
          ><fa-icon :icon="['far', 'award']" size="lg" fixed-size
        /></span>
        Se previsto dal tipo di evento: sollecitare l'autorizzazione elettronica
        da parte di un proprio capounità/capogruppo.
      </li>
    </ul>
    <p>
      <em
        >BuonaCaccia può processare in automatico solo le richieste di
        iscrizione da censiti AGESCI in regola.</em
      ><br />
      Per ogni necessit&agrave; particolare &eacute; possibile rivolgersi
      direttamente alla Segreteria che organizza l&#39;evento per una
      lavorazione assistita.
    </p>
    <p class="alert alert-info">
      Quanto necessario a completare l&#39;iscrizione deve pervenire entro
      <strong> <b-badge>9</b-badge> giorni calendariali</strong> dalla
      registrazione o
      <strong
        >l&#39;iscrizione verrà automaticamente ed irrevocabilmente
        cancellata</strong
      >
      dal sistema, perdendo la priorità acquisita, per lasciare spazio ad altre
      iscrizioni.<br />
      <strong
        >Questa scadenza non tiene conto delle chiusure ordinarie e
        straordinarie della Segreteria Regionale</strong
      >: è responsabilità di chi seguir&agrave; l'iscrizione registrare
      l&#39;iscrizione stessa quando è in grado di far pervenire quanto
      necessario <em>in orario di apertura della Segreteria Regionale</em> per
      la lavorazione entro la scadenza.
    </p>
    <p class="alert alert-warning">
      In caso di impossibilità a partecipare ad un evento, &egrave; possibile
      disdire/ritirare la propria iscrizione direttamente dalla pagina di stato
      dell'iscrizione stessa.<br />
      Così facendo si darà modo di partecipare a chi &egrave; in lista d'attesa
      e,
      <strong
        >se entro <b-badge>3</b-badge> giorni dall'evento, si avrà diritto al
        rimborso della quota d'iscrizione</strong
      >.<br />
      <em
        >Se il pagamento è stato effettuato in elettronico, il rimborso verrà
        emesso automaticamente dal sistema.</em
      >
    </p>

    <div>
      <b-button
        @click="EulaClick"
        v-if="!EulaAccepted"
        class="font-weight-bold"
        variant="primary"
        >Dichiaro di aver compreso le indicazioni!</b-button
      >
      <p v-if="EulaAccepted">
        Vai alla pagina delle
        <router-link :to="{ name: 'subscribe' }">iscrizioni</router-link>.
      </p>
    </div>

    <p class="small text-right">Istruzioni aggiornate al 20/02/2016</p>
  </div>
</template>

<style scoped>
.spaced-list-item {
  margin-bottom: 6px;
}
</style>

<script>
import Toaster from "../../mixins/toaster.js";

export default {
  name: "Instructions",
  mixins: [Toaster],
  computed: {
    EulaAccepted() {
      return this.$store.state.EulaAccepted;
    },
  },
  methods: {
    EulaClick() {
      this.$store.commit("setEulaAcceptance", true);
      this.toast(
        "info",
        "Istruzioni",
        "Ora non potrai dire che non lo sapevi... ;)"
      );
    },
  },
};
</script>
