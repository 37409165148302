<template>
  <div>
    <div class="alert alert-info text-center" v-if="!isRegionSelected">
      Per aiutarti nella navigazione, seleziona la tua regione di
      provenienza:&nbsp;
      <region-picker
        size="sm"
        v-model="regionCode"
        style="width: auto"
      ></region-picker>
    </div>

    <div v-for="(alert, idx) in alerts" :key="idx" :class="alert.class">
      {{ alert.text }}
    </div>
  </div>
</template>

<script>
import RegionPicker from "./RegionPicker.vue";

export default {
  name: "AlertsPanel",
  components: { RegionPicker },
  data() {
    return {
      regionCode: "",
      alerts: [
        // { class: "alert alert-danger", text: "Danger test" }
      ],
    };
  },
  computed: {
    isRegionSelected() {
      return this.$store.state.WReg != "";
    },
  },
  watch: {
    regionCode: function (value) {
      this.$store.commit("setWorkingRegion", value);
      localStorage.WReg = value;
    },
  },
};
</script>
