<template>
  <div>
    <h2>Eventi in programmazione</h2>

    <b-card bg-variant="light">
      <b-form inline>
        <region-picker
          class="mb-3"
          v-model="regionFilter"
          empty-option="allRegions"
        ></region-picker
        >&nbsp;
        <b-form-select
          v-model="categoryFilter"
          :options="categories"
          class="mb-3"
        >
          <template #first>
            <option value>Tutte le categorie</option>
          </template> </b-form-select
        >&nbsp;
        <b-form-select v-model="sortBy" class="mb-3">
          <option value="categoryFullName">Ordina per tipologia</option>
          <option value="eventFrom">Ordina per partenza</option>
          <option value="title">Ordina per titolo</option> </b-form-select
        >&nbsp;&nbsp;&nbsp;
        <b-form-checkbox id="cbOnlyOpen" v-model="opennessFilter">
          Solo eventi aperti o da aprire
        </b-form-checkbox>
      </b-form>
    </b-card>

    <br />

    <p class="text-muted" v-if="!loaded">Caricamento lista eventi...</p>

    <b-table
      striped
      hover
      :items="events"
      :fields="fields"
      :filter="filter"
      :filter-function="filterItem"
      :sort-by.sync="sortBy"
      v-if="loaded"
    >
      <template #cell(branch)="data">
        <img :src="getBranchImage(data.value)" :alt="data.value" />
      </template>
      <template #cell(eventFrom)="data">
        {{ moment(data.value).format("L") }}
      </template>
      <template #cell(eventTo)="data">
        {{ moment(data.value).format("L") }}
      </template>
      <template #cell(fee)="data"> €{{ data.value }}.00 </template>
      <template #cell(semaphore)="data">
        <img :src="getSemaphoreImage(data.value)" :alt="data.value" />
      </template>
    </b-table>
  </div>
</template>

<script>
import RegionPicker from "../../shared/RegionPicker.vue";
import Toaster from "../../mixins/toaster.js";

export default {
  name: "EventList",
  mixins: [Toaster],
  components: { RegionPicker },
  data() {
    return {
      loaded: false,
      events: null,
      categories: [],
      filter: "true",
      regionFilter: this.$store.state.WReg,
      categoryFilter: "",
      opennessFilter: true,
      sortBy: "categoryFullName",
      fields: [
        { key: "branch", label: "" },
        { key: "categoryFullName", label: "Categoria" },
        { key: "title", label: "Titolo" },
        { key: "regionName", label: "Regione" },
        { key: "eventFrom", label: "Partenza" },
        { key: "eventTo", label: "Rientro" },
        { key: "fee", label: "Quota" },
        { key: "location", label: "Localit&agrave;" },
        { key: "activeSubscriptions", label: "Iscritti" },
        { key: "semaphore", label: "Stato" },
      ],
    };
  },
  watch: {
    regionFilter: function () {
      this.filter = "true";
    },
    categoryFilter: function () {
      this.filter = "true";
    },
    opennessFilter: function () {
      this.filter = "true";
    },
  },
  methods: {
    filterItem(item, filter) {
      return (
        (this.regionFilter == "" ? true : item.regionID == this.regionFilter) &&
        (this.categoryFilter == ""
          ? true
          : item.categoryID == this.categoryFilter) &&
        (this.opennessFilter ? Boolean(item.isSubscribable) : true)
      );
    },
    getSemaphoreImage(light) {
      return require("../../images/light_" + light + ".png");
    },
    getBranchImage(branch) {
      switch (branch) {
        case "1":
          return require("../../images/branch_lc.png");
        case "2":
          return require("../../images/branch_eg.png");
        case "3":
          return require("../../images/branch_rs.png");
        case "4":
          return require("../../images/branch_fc.png");
        case "5":
          return require("../../images/branch_aa.png");
      }
    },
  },
  mounted() {
    if (this.$store.state.Categories) {
      this.categories = this.$store.state.Categories;
    } else {
      this.$http
        .get("/v0.1/uiassist/categories")
        .then((response) => {
          this.categories = response.data;
          this.$store.commit("setCategories", response.data);
        })
        .catch(() => {
          this.toast(
            "danger",
            "Lista Eventi",
            "Ooops! Qualcosa è andato storto nel caricamento della lista delle categorie..."
          );
        });
    }

    this.$http
      .get("/v0.1/events")
      .then((response) => {
        this.events = response.data;
        this.loaded = true;
      })
      .catch(() => {
        this.toast(
          "danger",
          "Lista Eventi",
          "Ooops! Qualcosa è andato storto nel caricamento della lista degli eventi..."
        );
      });
  },
};
</script>
