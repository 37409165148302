export const regions = [
  { id: "B", name: "Abruzzo" },
  { id: "C", name: "Basilicata" },
  { id: "D", name: "Calabria" },
  { id: "E", name: "Campania" },
  { id: "F", name: "Emilia Romagna" },
  { id: "G", name: "Friuli Venezia Giulia" },
  { id: "H", name: "Lazio" },
  { id: "I", name: "Liguria" },
  { id: "L", name: "Lombardia" },
  { id: "M", name: "Marche" },
  { id: "N", name: "Molise" },
  { id: "O", name: "Piemonte" },
  { id: "P", name: "Puglia" },
  { id: "Q", name: "Sardegna" },
  { id: "R", name: "Sicilia" },
  { id: "S", name: "Toscana" },
  { id: "T", name: "Trentino" },
  { id: "U", name: "Umbria" },
  { id: "V", name: "Val d'Aosta" },
  { id: "Z", name: "Veneto" },
];
