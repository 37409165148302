<template>
  <div>
    <bc-navbar-public />

    <b-container class="content-spacer-lg">
      <alerts-panel />
      <router-view />
    </b-container>

    <bc-footer-public />
  </div>
</template>

<script>
import bcNavbarPublic from "./components/NavbarPublic.vue";
import bcFooterPublic from "./components/FooterPublic.vue";
import alertsPanel from "../shared/AlertsPanel.vue";

export default {
  name: "LayoutPublic",
  components: {
    "bc-footer-public": bcFooterPublic,
    "bc-navbar-public": bcNavbarPublic,
    "alerts-panel": alertsPanel,
  },
};
</script>
